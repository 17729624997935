<template>
  <div
    class="v4-home-container"
    :style="{
      height: '100vh'
    }"
  >
    <div class="v4-home-wrapper" ref="wrapper">
      <div class="index-loading-show" v-if="homeAnimatedHasPlayed === 0">
        <div class="text-container">
          <div class="text__logo" ref="text__logo"></div>
          <div class="text__slide-container" ref="slide-wrapper">
            <ul ref="slideParent" :class="[noTransition ? 'no-transition' : '']">
              <li class="textSlide" v-for="(text, index) in slideTexts" :key="index">
                {{ text }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="v4-home-swiper-container"
        :style="{
          height: $bowserMobile ? `${$screenHeight}px` : '100vh'
        }"
      >
        <swiper :options="swiperOptions" ref="mySwiper" v-if="slides">
          <swiper-slide
            class="swiper-slide swiper-no-swiping"
            v-for="(item, index) in slides"
            :key="index"
            @click.native="goJump(item.link)"
            style="cursor:pointer;"
          >
            <div class="swiper__img-box">
              <img :src="$bowserMobile ? item.src.mobile : item.src.pc" alt="" />
            </div>
            <div class="swiper__text">
              <p>{{ item.category_text }}</p>
              <p>{{ item.name }}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper__pagination">
          <div
            class="pagination__item"
            v-for="(qq, q) in slides"
            :key="q"
            :class="swipserCurrentIndex === q ? 'actived' : ''"
          >
            <div v-if="$bowserMobile" class="pagination__hint">
              <p>{{ 1 + q }}</p>
              <p>{{ qq.category_text }}</p>
            </div>
            <div class="line">
              <div class="inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { TweenMax } from 'gsap';
import { preloadImgs } from '@/assets/utils/common';
import 'swiper/css/swiper.css';
import { mapState } from 'vuex';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const vue = this;
    return {
      slideTexts: ['INTERIOR', 'ARCHITECTURE', 'LANDSCAPE', 'URBAN PLANNING'],
      lineHeight: !1,
      currIndex: -1,
      interval: null,
      noTransition: !0,
      isFinished: false,
      height: !1,
      slides: null,
      swipserCurrentIndex: 0,
      swiperOptions: {
        init: false,
        autoplay: {
          delay: 4000,
        },
        loop: true,
        speed: 1000,
        watchSlidesProgress: true,
        noSwiping: true,
        on: {
          init() {
            const swiper = this;
            const currentImgBox = document.querySelector(
              '.swiper-slide-active .swiper__img-box img',
            );
            const duration = swiper.passedParams.autoplay.delay + swiper.passedParams.speed;
            TweenMax.fromTo(
              currentImgBox,
              duration / 1000,
              {
                scale: 1.05,
              },
              {
                scale: 1,
              },
            );
          },
          slidePrevTransitionStart() {
            const swiper = this;
            const duration = swiper.passedParams.autoplay.delay + swiper.passedParams.speed;
            const currentLine = Array.from(document.querySelectorAll('.pagination__item')).find(
              (item, index) => index === swiper.realIndex,
            );
            vue.swipserCurrentIndex = swiper.realIndex;
            TweenMax.fromTo(
              currentLine.querySelector('.inner'),
              duration / 1000,
              {
                x: '-100%',
              },
              {
                x: '0',
                onComplete() {
                  const text = document.querySelectorAll('.swiper__text p');
                  TweenMax.set(text, {
                    opacity: 0,
                    y: 30,
                  });
                  TweenMax.set(currentLine.querySelector('.inner'), {
                    x: '-100%',
                  });
                },
              },
            );

            const currentImgBox = document.querySelector(
              '.swiper-slide-active .swiper__img-box img',
            );
            TweenMax.fromTo(
              currentImgBox,
              (duration + swiper.passedParams.speed) / 1000,
              {
                scale: 1.05,
              },
              {
                scale: 1,
                onComplete() {
                  TweenMax.set(currentImgBox, {
                    scale: 1.05,
                  });
                },
              },
            );
          },
          slideNextTransitionStart() {
            const swiper = this;
            const duration = swiper.passedParams.autoplay.delay + swiper.passedParams.speed;
            const currentLine = Array.from(document.querySelectorAll('.pagination__item')).find(
              (item, index) => index === swiper.realIndex,
            );
            vue.swipserCurrentIndex = swiper.realIndex;
            TweenMax.fromTo(
              currentLine.querySelector('.inner'),
              duration / 1000,
              {
                x: '-100%',
              },
              {
                x: '0',
                onComplete() {
                  const text = document.querySelectorAll('.swiper__text p');
                  TweenMax.set(text, {
                    opacity: 0,
                    y: 30,
                  });
                  TweenMax.set(currentLine.querySelector('.inner'), {
                    x: '-100%',
                  });
                },
              },
            );

            const currentImgBox = document.querySelector(
              '.swiper-slide-active .swiper__img-box img',
            );
            TweenMax.fromTo(
              currentImgBox,
              (duration + swiper.passedParams.speed) / 1000,
              {
                scale: 1.05,
              },
              {
                scale: 1,
                onComplete() {
                  TweenMax.set(currentImgBox, {
                    scale: 1.05,
                  });
                },
              },
            );
          },
          transitionEnd() {
            // const swiper = this;
            const currentText = document.querySelectorAll('.swiper-slide-active .swiper__text p');
            TweenMax.staggerFromTo(
              currentText,
              0.5,
              {
                opacity: 0,
                y: 30,
              },
              {
                opacity: 1,
                y: 0,
              },
              0.1,
            );
          },
        },
      },
      rAf: null,
      loopId: null,
    };
  },
  computed: {
    ...mapState(['homeAnimatedHasPlayed', '$bowserMobile']),
    firstSlideText() {
      return this.slideTexts[0];
    },
  },
  watch: {
    currIndex() {
      this.applyDisplacement();
    },
    $route() {
      // 获取图片
      this.getImgs();
      if (this.$bowserMobile) {
        document.body.classList.add('red-logo');
      }
    },
    async slides(val) {
      const imgs = val.map((item) => item.src.pc);
      await preloadImgs(imgs);
      this.isFinished = false;
      if (this.homeAnimatedHasPlayed === 1) {
        this.loadReady();
      }
      this.$nextTick(() => {
        if (this.$refs.mySwiper) {
          this.$refs.mySwiper.$swiper.init();
        }
      });
    },
  },
  mounted() {
    const that = this;
    that.$slideWrapper = this.$refs['slide-wrapper'];
    that.$slide = this.$refs.slideParent;
    that.$wrapper = this.$refs.wrapper;
    if (this.$bowserMobile) {
      document.body.classList.add('red-logo');
    }
    if (this.homeAnimatedHasPlayed === 0) {
      that.initSlideText();
      that.loopId = setTimeout(() => {
        that.textLoop();
      }, 500);
    }
    that.$nextTick(() => {
      if (that.homeAnimatedHasPlayed === 0) {
        that.$refs.text__logo.style.setProperty('opacity', 1);
        that.height = document.querySelector('.index-loading-show').offsetHeight;
      }
      // window.addEventListener('resize', this.onHomeResize);
    });
    this.getImgs();
  },
  methods: {
    onHomeResize() {
      window.location.reload();
    },
    initSlideText() {
      this.lineHeight = this.$slide.firstChild.offsetHeight;
      this.$slideWrapper.style.setProperty('--height', ''.concat(this.lineHeight, 'px'));
      // this.applyDisplacement();
    },
    applyDisplacement() {
      const index = this.currIndex;
      const e = -index * this.lineHeight;
      console.log(index);
      console.log(e);
      this.$slide.style.transform = 'translateY('.concat(e, 'px)');
    },
    startSlide() {
      let e;
      let t;
      let n;
      let o;
      this.interval = ((e = this.slideLoop),
      (t = 350),
      (n = new Date().getTime()),
      ((o = {}).value = window.requestAnimationFrame(function r() {
        if (new Date().getTime() - n >= t) {
          e.call();
          n = new Date().getTime();
        }
        o.value = window.requestAnimationFrame(r);
      })),
      o);
    },
    slideLoop() {
      let e;
      let t;
      let n;
      let o;
      this.noTransition = !1;
      this.currIndex += 1;
      this.applyDisplacement();
      if (this.currIndex === this.$slide.childElementCount - 1) {
        e = this.onWholeLoopFinish;
        t = 350;
        n = new Date().getTime();
        (o = {}).value = window.requestAnimationFrame(function r() {
          if (new Date().getTime() - n >= t) {
            e.call();
          } else {
            o.value = window.requestAnimationFrame(r);
          }
        });
      }
    },
    onWholeLoopFinish() {
      this.noTransition = !0;
      this.currIndex = this.$slide.childElementCount - 1;
      this.isFinished = true;
      this.applyDisplacement();
      this.notifyFinished();
    },
    notifyFinished() {
      const that = this;
      if (that.isFinished) {
        that.$nextTick(() => {
          that.loadReady();
        });
      }
    },
    loadReady() {
      this.$wrapper.classList.add('page-loaded');
      setTimeout(
        () => {
          document.body.classList.remove('red-logo');
        },
        this.homeAnimatedHasPlayed === 0 ? 1800 : 0,
      );
      setTimeout(() => {
        if (this.homeAnimatedHasPlayed === 0) {
          this.$store.state.homeAnimatedHasPlayed = 1;
        }
      }, 2500);
    },
    getImgs() {
      const that = this;
      this.$fly
        .get('api/scroll/list')
        .then((res) => {
          that.slides = res.data;
        })
        .catch(() => {
          alert('network error');
        });
    },
    goJump(link) {
      if (link) {
        window.location.href = link;
      }
    },
    textLoop() {
      const that = this;
      const playMode = 'once';
      const textLength = that.slideTexts.length;
      that.noTransition = false;
      if (playMode === 'once') {
        if (that.currIndex < textLength - 1) {
          that.currIndex += 1;
          that.loopId = setTimeout(() => {
            that.textLoop();
          }, 500);
        } else {
          clearTimeout(that.loopId);
          that.loopEnd();
        }
      }
    },
    loopEnd() {
      this.noTransition = true;
      this.loadReady();
    },
  },
};
</script>

<style>
body {
  background-color: #fff;
}
</style>
